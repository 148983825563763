import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Meeting Schedule")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c(VList,{staticClass:"pt-0 mt-n1"},_vm._l((_vm.meetingSchedule),function(data,index){return _c(VListItem,{key:data.avatar,class:("d-flex align-center px-0 " + (index > 0 ? 'mt-3':''))},[_c(VListItemAvatar,{attrs:{"size":"38"}},[_c(VImg,{attrs:{"src":data.avatar}})],1),_c('div',{staticClass:"d-flex align-center flex-wrap text-no-wrap flex-grow-1"},[_c('div',[_c(VListItemTitle,{staticClass:"text-sm font-weight-medium"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"14"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCalendarBlankOutline)+" ")]),_c(VListItemSubtitle,{staticClass:"text-xs"},[_vm._v(" "+_vm._s(data.dateAndTime)+" ")])],1)],1),_c(VSpacer),_c(VChip,{class:("v-chip-light-bg " + (data.chipColor) + "--text font-weight-semibold mt-1"),attrs:{"small":"","color":data.chipColor}},[_vm._v(" "+_vm._s(data.chipText)+" ")])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }